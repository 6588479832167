export const environment = {

  baseUrl: 'https://api.simpo.ai/',
  businessWithOutGateway:'https://prod-business.simpo.ai/',
  serverIp: 'https://dev-socialmedia.proficornlabs.com/',
  regenerateUrl : 'https://prod-admin.simpo.ai/',
  redirectingUrl :'https://simpo.ai',
  redirectingSiteUrl : 'https://app.simpo.ai',
  urlEndingPoint :"simpo.ai",
  production: true,
  firebase: {
    apiKey: "AIzaSyB0uWmX7n-GjrZOh0KRXtGG9bgrO0R9fYI",
  authDomain: "beeos-d3a05.firebaseapp.com",
  projectId: "beeos-d3a05",
  storageBucket: "beeos-d3a05.appspot.com",
  messagingSenderId: "264451984597",
  appId: "1:264451984597:web:df34ef53cc1f822b5aa65c",
  measurementId: "G-PCTKBE8GCX"
  },
  imageUploadSize: 1000000,
  image: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'ap-south-1:952707e8-9a77-4293-b3b2-8a02fb15b371',
          Region: 'ap-south-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'prod-simpo',
        Region: 'ap-south-1',
      },
    },
  },
  componentImageUploading: {
    Version: 1.0,
    CredentialsProvider: {
      CognitoIdentity: {
        Default: {
          PoolId: 'ap-south-1:952707e8-9a77-4293-b3b2-8a02fb15b371',
          Region: 'ap-south-1',
        },
      },
    },
    IdentityManager: {
      Default: {},
    },
    S3TransferUtility: {
      Default: {
        Bucket: 'prod-simpo',
        Region: 'ap-south-1',
      },
    },
  },

  //mapBox for google maps
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibmFpZHUyNTA3IiwiYSI6ImNsZWxjcGlmZTB0ejkzb3BnMndzbmthM2cifQ.BJoCPnd81NYvx_2VUadF2w',
  },

  //goDaddy SSO Key for dev environment
  goDaddy: {
    ssoKey:
      'sso-key 3mM44UcgzgNc8W_PW9i1fz5K8SYbVT1Mzg1i:PW2vd4zTxF8orAqBYD9VXs',
  },
};
